<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fa">
        <li class="left">
          <router-link to="/team">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.inviteTasks") }}</li>
      </ul>
    </div>
    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      style="position: relative; top: 0; height: calc(100%)"
      v-if="alreadyReq"
    >
      <div class="main main_page">
        <div style="height:40px"></div>
        <div class="team_activity" v-if="alreadyReq">
          <div class="no_data" v-if="!listItems || listItems.length == 0">
            <p>{{ t("common.message.noRecords") }}</p>
          </div>
          <ul v-else :key="index" v-for="(item, index) in listItems">
            <h3>{{ item.taskName }}</h3>
            <h2>
              <span>
                {{ t("team.label.bonus") }}:
                <b>{{ formatMoney(item.awardAmount) }}</b>
              </span>
              <span>
                {{ t("team.label.expireIn") }}
                <b>{{ item.expireDay }}</b>
                {{ t("team.label.days") }}
              </span>
              <span>
                <b>{{ item.taskSchedule }}/{{ item.taskQuantity }}</b>
              </span>
            </h2>
            <li>
              <button
                type="button"
                class="btn_a"
                v-if="'' + item.taskStatus === '2'"
                @click="take(item,index)"
              >
                <!-- 待领取 -->
                {{ t(`common.taskStatus.${item.taskStatus}`) }}
              </button>
              <button
                type="button"
                class="btn_b"
                v-else-if="'' + item.taskStatus === '1'"
              >
                <!-- 进行中 -->
                {{ t(`common.taskStatus.${item.taskStatus}`) }}
              </button>
              <button
                type="button"
                class="btn_c"
                disabled
                v-else-if="'' + item.taskStatus === '3'"
              >
                <!-- 已领取 -->
                {{ t(`common.taskStatus.${item.taskStatus}`) }}
              </button>
              <button type="button" disabled v-else>
                <!-- 已过期 -->
                {{ t(`common.taskStatus.${item.taskStatus}`) }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </BetterScroll>
  </div>
</template>
<script>
import { ref, onMounted, reactive,watch } from "vue";

import {
  formatMoney,
  // formatPercentage,
  // formatShortDateTime,
} from "@/utils/formatter";
import { useI18n } from "@/lang";
import BetterScroll from "@/components/BetterScroll.vue";
import api from "@/api";
export default {
  components: { BetterScroll },
  setup() {
    const { t } = useI18n();
    const alreadyReq = ref(false);
    const scrollRef = ref(null);
    const isStopReq = ref(false);
    const listItems = reactive([]);
    const currentPage = ref(0);
    onMounted(() => {
      fetchMorePage();
    });

    const take = async (item,index) => {
      let res = await api.receiveTaskReward({ taskId: item.id });
      if (res?.data?.success) {
        listItems[index].taskStatus = '3'
      }
    };
    const fetchMorePage = () => {
      if (isStopReq.value) {
        return;
      }
      api
        .taskSchedule({
          current: currentPage.value + 1,
          size: 8,
        })
        .then((res) => {
          if (res?.data?.success) {
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value + 1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          }
        });
    };
    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });
    return {
      t,
      formatMoney,
      listItems,
      take,
      alreadyReq,
      scrollRef,
      fetchMorePage
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0;
}
</style>
